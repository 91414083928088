import { Pinia, Store } from '../pinia-class-component';
import { Entity } from '@/models/types/Entity';
import { entityTypes, Status } from '@/enums/generic';
import { useBuildingStore } from '@/store/entities/buildingStore';
import { useFormStore } from '@/store/form/formStore';
import { Form } from '@/models/Form';
import { WebEvents } from '@/native/plugins/WebEvents';

export function useLayoutStore() {
  return new LayoutStore();
}

class LoaderStatus {
  text?: string;
  status?: Status;

  constructor(text = '', status = Status.PENDING) {
    this.text = text;
    this.status = status;
  }
}

@Store
export class LayoutStore extends Pinia {
  private _isUserMenuOpen: boolean;
  private _isDarkMode: boolean;
  private _appVersionName: string;
  private _currentRoute: string;
  private _loader: any;

  constructor() {
    super();
    this._isOnline = true;
    this._isSideMenuOpen = false;
    this._isFormDialogOpen = false;
    this._isCancelFormPromptOpen = false;
    this._isUserMenuOpen = false;
    this._isLoading = false;
    this._loader = new LoaderStatus();
    this._currentNode = undefined;
    this._isDarkMode = false;
    this._appVersionName = '';
    this._currentRoute = '';
  }

  private _isOnline: boolean;

  get isOnline(): boolean {
    return this._isOnline;
  }

  private _isSideMenuOpen: boolean;

  get isSideMenuOpen(): boolean {
    return this._isSideMenuOpen;
  }

  private _isFormDialogOpen: boolean;
  private _isCancelFormPromptOpen: boolean;

  get isFormDialogOpen(): boolean {
    return this._isFormDialogOpen;
  }

  setCancelPromptState(state: boolean) {
    this._isCancelFormPromptOpen = state;
  }

  get isCancelFormPromptOpen(): boolean {
    return this._isCancelFormPromptOpen;
  }

  private _isLoading: boolean;

  get isLoading(): boolean {
    return this._isLoading;
  }

  get loader(): boolean {
    return this._loader;
  }

  private _currentNode: Entity | undefined;

  get currentNode(): Entity | undefined {
    return this._currentNode;
  }

  changeCurrentNode(node: Entity) {
    this._currentNode = node;

    if (node.type === entityTypes.building && node.id) {
      const buildingStore = useBuildingStore();
      buildingStore.setCurrentBuilding(node.id);
    }

    const formStore = useFormStore();
    const form = new Form().setName(node.type).setId(node.id);

    const parent = node.getParentId();
    if (parent !== undefined) {
      form.setParentId(parent);
    }
    formStore.setCurrentForm(form);
  }

  deactivateDarkMode() {
    this._isDarkMode = false;
    document.documentElement.classList.remove('dark');
    window.localStorage.setItem('isDarkMode', 'false');
    WebEvents.onThemeToggle({ isDarkMode: false });
  }

  activateDarkMode() {
    this._isDarkMode = true;
    document.documentElement.classList.add('dark');
    window.localStorage.setItem('isDarkMode', 'true');
    WebEvents.onThemeToggle({ isDarkMode: true });
  }

  disableDarkModeTemporarily() {
    document.documentElement.classList.remove('dark');
  }

  isDarkMode(): boolean {
    return this._isDarkMode;
  }

  toggleUserMenu() {
    this._isUserMenuOpen = !this._isUserMenuOpen;
  }

  openSideMenu() {
    this._isSideMenuOpen = true;
  }

  openFormDialog() {
    this._isFormDialogOpen = true;
    this._isCancelFormPromptOpen = false;
  }

  closeSideMenu() {
    this._isSideMenuOpen = false;
  }

  closeFormDialog() {
    this._isFormDialogOpen = false;
    this._isCancelFormPromptOpen = false;
    useFormStore().resetCurrentForm();
  }

  toggleSideMenu() {
    this._isSideMenuOpen = !this._isSideMenuOpen;
  }

  setOnlineState(state: boolean) {
    this._isOnline = state;
  }

  setLoadingState(state: boolean) {
    this._loader = { text: '', status: Status.PENDING };
    this._isLoading = state;
  }

  resolveLoaderState(text: string, isSuccessful: boolean) {
    const status = isSuccessful ? Status.SUCCESS : Status.ERROR;
    this._loader = new LoaderStatus(text, status);

    setTimeout(() => {
      this._loader = new LoaderStatus();
    }, 2000);
  }

  getAppVersionName() {
    return this._appVersionName;
  }

  setRoute(route: string) {
    this._currentRoute = route;
  }

  goBack() {
    if (this._isFormDialogOpen) {
      if (this._isCancelFormPromptOpen) {
        this.closeFormDialog();
        return;
      }

      if (useFormStore().hasFormChanges) {
        this.setCancelPromptState(true);
        return;
      }

      this.closeFormDialog();
      return;
    }

    if (this._currentRoute === 'Home') {
      return;
    }

    window.history.back();
  }
}
