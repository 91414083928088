import { Building } from '@/models';
import { useRoomGroupStore } from '@/store/entities/roomGroupStore';
import { Store } from '@/store/pinia-class-component';
import { miniEntityStore } from '@/store/entities/types/miniEntityStore';
import { useInspectedBuildingStore } from '@/store/entities/inspectedBuildingStore';
import { Address } from '@/models/types/Address';
import { useOrderStore } from '@/store/order/orderStore';

export function useBuildingStore() {
  return new BuildingStore();
}

@Store
export class BuildingStore extends miniEntityStore {
  private _buildings: Map<string, Building>;
  private _buildingId?: string;
  private _buildingStruct: any;

  constructor() {
    super();
    this._buildings = new Map();
    this._buildingId = undefined;
    this._buildingStruct = undefined;
  }

  get buildingAddresses(): Array<Address> {
    return useOrderStore().getOrderAddresses();
  }

  get buildings(): Map<string, Building> {
    return this._buildings;
  }

  get buildingId(): string | undefined {
    return this._buildingId;
  }

  get buildingStruct(): any {
    return this._buildingStruct;
  }

  addEntity(building: Building): void {
    this._buildings.set(<string>building.id, building);
  }

  getBuildings() {
    return Array.from(this._buildings.values());
  }

  resetState() {
    this._buildings = new Map();
    this._buildingId = undefined;
  }

  deleteEntity(entity: Building): void {
    const roomGroupStore = useRoomGroupStore();
    const allRoomgroups = roomGroupStore.getRoomGroupsByBuildingId(
      <string>entity.id
    );

    if (allRoomgroups.length > 1) {
      throw new Error("Can't delete building with more than 1 roomgroup");
    }

    if (allRoomgroups.length === 1) {
      roomGroupStore.deleteEntity(allRoomgroups[0]);
    }
    if (useOrderStore().isOnSiteInspection()) {
      const inspectedBuilding =
        useInspectedBuildingStore().getInspectedBuildingByBuildingId(entity.id);
      if (inspectedBuilding) {
        useInspectedBuildingStore().deleteEntity(inspectedBuilding);
      }
    }

    this._buildings.delete(entity.id);
  }

  setBuilding(building: Building) {
    if (building.id != null) {
      this._buildingId = building.id;
    }
  }

  getCurrentBuilding() {
    if (this._buildingId) {
      return this._buildings.get(this._buildingId);
    }
    return undefined;
  }

  setCurrentBuilding(nodeId: string) {
    this._buildingId = nodeId;
  }

  setBuildingStruct(struct: any) {
    this._buildingStruct = struct;
  }
}
