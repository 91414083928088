import { NO_SIGNAL_ICON, NOT_MOUNTED_ICON } from '@/enums/icons';

const dimensions = {
  maxWidth: 0,
  maxHeight: 0,
};
/**
 * Enables drawing of arrows for swapping.
 */
let arrowMode = false;
let options;
let canvas;

export const createCanvas = function (node, canvasObject, canvasOptions) {
  options = canvasOptions;
  canvas = canvasObject;
  redraw(node);
  return { redraw };
};

function getMarginX() {
  return arrowMode ? 1.5 * options.marginX : options.marginX;
}
/**
 * Create a new Node. The point are the coordinates of the upper left corner.
 * @param point coordinates of the upper left corner
 * @param node node based on which the tile will be created
 * @return {HTMLDivElement} new node
 */
function createNode(point, node) {
  const newTile = document.createElement('div');
  newTile.style.left = point.x + 'px';
  newTile.style.top = point.y + 'px';
  newTile.style.height = options.boxHeight + 'px';
  newTile.style.width = options.boxWidth + 'px';

  if (node.totalNumberOfChildren > 0) {
    const totalTileWidth =
      options.boxWidth * node.totalNumberOfChildren +
      getMarginX() * (node.totalNumberOfChildren - 1);
    newTile.style.width = totalTileWidth + 'px';
  }

  newTile.style.background = node.background;
  if (newTile.style.border) {
    newTile.style.border = node.border;
  }

  if (node.isActive) {
    newTile.className = 'node room-node  active-node';
    if (node.children.length > 0) {
      newTile.style.width =
        parseInt(newTile.style.width) + options.boxWidth + getMarginX() + 'px';
    }
    const addRoomGroupTile = createAddRoom(node, newTile);
    newTile.appendChild(addRoomGroupTile);
  } else {
    const findActive = (node) => {
      return node.children?.some((child) => {
        if (child.isActive && child.children.length > 0) return true;
        return findActive(child);
      });
    };
    if (findActive(node)) {
      newTile.style.width =
        parseInt(newTile.style.width) + options.boxWidth + getMarginX() + 'px';
    }

    const findSiblings = (node) => {
      return node?.siblings?.some((sibling) => {
        if (
          (sibling.isActive || findActive(sibling)) &&
          sibling.children.length > 0 &&
          sibling.ordinal < node.ordinal
        ) {
          return true;
        }
        return findSiblings(sibling);
      });
    };

    if (findSiblings(node)) {
      newTile.style.left =
        parseInt(newTile.style.left) + options.boxWidth + getMarginX() + 'px';
    }

    const findParent = (node) => {
      if (!node.parent) {
        return false;
      }
      if (findSiblings(node.parent)) return true;

      return findParent(node.parent);
    };

    if (findParent(node)) {
      newTile.style.left =
        parseInt(newTile.style.left) + options.boxWidth + getMarginX() + 'px';
    }

    newTile.className = 'node room-node inactive-node';
  }

  const headerHtml = `<div class="flex flex-row justify-between items-center order-1"></div>`;
  const header = createHtmlElements(headerHtml);
  if (!node.isAccessible) {
    header.appendChild(
      createHtmlElements(
        `<em class="mdi mdi-cancel text-light-warning dark:text-dark-warning mr-1"></em>`
      )
    );
  }
  header.appendChild(createHtmlElements(`<span>${node.label}</span>`));
  newTile.appendChild(header);

  if (node.arrows.left && arrowMode) {
    newTile.appendChild(createArrow(node));
  }

  if (node.tags?.length >= 1) {
    const tagsHtml = `<div class="tags order-2"></div>`;
    const el = createHtmlElements(tagsHtml);

    node.tags.forEach((tag) => {
      const tagData = `<span class="tag text-zinc-200" style="background: ${tag.color}"><span class="font-bold text-white mr-2">${tag.info}</span> ${tag.label}</span>`;
      el.appendChild(createHtmlElements(tagData));
    });
    3;
    newTile.appendChild(el);
  }

  const secondaryLabelsHtml = createHtmlElements(
    `<div class="secondary-labels tags flex flex-row gap-2 order-3"></div>`
  );
  newTile.appendChild(secondaryLabelsHtml);

  if (node.notMountedDevices?.length > 0) {
    const tagsHtml = `<span class="tag bg-dark-error"><span class="font-bold text-base mr-1">${node.notMountedDevices.length}</span><em class="mdi ${NOT_MOUNTED_ICON} text-base"></em></span>`;
    const el = createHtmlElements(tagsHtml);

    secondaryLabelsHtml.appendChild(el);
  }

  if (node.radioCheckErrors?.length > 0) {
    const tagsHtml = `<span class="tag bg-dark-error"><span class="font-bold text-base mr-1">${node.radioCheckErrors.length}</span><em class="mdi ${NO_SIGNAL_ICON} text-base"></em></span>`;
    const el = createHtmlElements(tagsHtml);
    secondaryLabelsHtml.appendChild(el);
  }

  if (node.installationLocation) {
    const tagsHtml = `<span class="tag text-dark-2 bg-light-0 border border-light-success dark:text-light-2 dark:bg-dark-0 dark:border-dark-success"><em class="mdi mdi-router-wireless mr-1" data-testId="installationLocationTag"></em><span>Gateway</span></span>`;
    const el = createHtmlElements(tagsHtml);
    secondaryLabelsHtml.appendChild(el);
  }

  return newTile;
}

function createAddRoom(node, tile) {
  const addRoomTile = createHtmlElements(
    `<div class="flex flex-col justify-center rounded-md border-2 border-dashed border-primary-2 connection" data-testId="addRoomButton"></div>`
  );
  addRoomTile.style.height = options.boxHeight + 'px';
  addRoomTile.style.width = options.boxWidth + 'px';
  addRoomTile.style.top = -(options.boxHeight + options.marginY) + 'px';

  if (node.children.length > 0) {
    addRoomTile.style.left =
      parseInt(tile.style.width) - options.boxWidth + 'px';
  }
  addRoomTile.style.position = 'absolute';
  addRoomTile.addEventListener('click', (event) => {
    options.groups.addRoom();
    event.stopPropagation();
  });
  addRoomTile.appendChild(
    createHtmlElements(
      `<div><em class="mdi text-3xl pl-1 pr-1 mdi-plus text-primary-2"></em></div>`
    )
  );

  return addRoomTile;
}

function createArrow(node) {
  const arrow = createHtmlElements(
    `<div><i class='fas fa fa-lg fa-exchange arrow-btn' data-testid='swapButton'></i><div>`
  );
  arrow.style.top = '50px';
  arrow.style.left = '-37px';
  arrow.style.position = 'absolute';
  arrow.addEventListener('click', (event) => {
    node.arrows.onClick();
    event.stopPropagation();
  });
  return arrow;
}

function createHtmlElements(html) {
  const template = document.createElement('template');
  html = html.trim(); // Never return a text node of whitespace as the result
  template.innerHTML = html;
  return template.content.firstChild;
}

function renderNode(node, point) {
  const x = point.x;
  const y = point.y;
  const boxWidth = options.boxWidth;
  const marginY = options.marginY;
  const marginX = getMarginX();
  const boxHeight = options.boxHeight;
  const nodeElement = createNode({ x, y }, node);

  canvas.appendChild(nodeElement);

  nodeElement.addEventListener('click', node.selfAction);
  if (!node.children) {
    return;
  }
  const childCount = node.children.length;

  let startX = x - ((childCount - 1) * (boxWidth + marginX)) / 2;
  if (node.children.length > 1) {
    startX = x;
  }

  const startY = y - marginY - boxHeight;

  let startMarginX = 0;
  node.children.forEach((child, index) => {
    let totalNumberOfChildren = 1;
    if (node.children[index - 1]) {
      totalNumberOfChildren =
        node.children[index - 1].totalNumberOfChildren || 1;
      startMarginX =
        (marginX + boxWidth) * totalNumberOfChildren + startMarginX;
    }

    point = {
      x: startX + startMarginX,
      y: startY,
    };
    renderNode(child, point);
  });

  if (node.isActive) {
    scrollNodeToCenter(nodeElement);
  }

  return nodeElement;
}

function cleanUpCanvas() {
  while (canvas.firstChild) {
    canvas.removeChild(canvas.firstChild);
  }
}

/**
 * Set the max width and max height of all tiles when displayed horizontal and
 * vertically.
 * @param node root node of canvas
 */
function setDimensions(node) {
  dimensions.maxWidth = 0;
  dimensions.maxHeight = 0;
  let level = 0;

  function calcDimensionChild(childNode) {
    // when the root does not have any children, make it 1 as default
    // to calculate the width and height with at least this 1 tile
    const childAmount = childNode.children.length || 1;

    const width =
      childAmount * options.boxWidth + (childAmount - 1) * getMarginX();
    if (width > dimensions.maxWidth) {
      dimensions.maxWidth = width;
    }
    const height = level * options.boxHeight + (level - 1) * options.marginY;
    if (height > dimensions.maxHeight) {
      dimensions.maxHeight = height;
    }

    childNode.children.forEach((child) => {
      level = level + 1;
      calcDimensionChild(child, options);
      level = level - 1;
    });
  }

  calcDimensionChild(node);
}

function scrollNodeToCenter(node) {
  const canvasParent = canvas.parentElement;
  const offsetLeft = canvasParent.offsetWidth - node.offsetWidth;
  const offsetTop = canvasParent.offsetHeight - node.offsetHeight;

  // Auto scrolling is only enabled when node is big enough
  if (node.offsetWidth < canvasParent.offsetWidth / 2) {
    return;
  }

  canvasParent.scrollTo(
    node.offsetLeft - offsetLeft / 2,
    node.offsetTop - offsetTop / 2
  );
}

function redraw(node, toggleArrowMode = false) {
  arrowMode = toggleArrowMode;

  cleanUpCanvas();
  setDimensions(node);
  let width = parseInt(canvas.width, 10);

  const rootTileWidth =
    node.totalNumberOfChildren * options.boxWidth +
    (node.totalNumberOfChildren - 1) * getMarginX();

  if (rootTileWidth > width) {
    width = rootTileWidth + getMarginX() * 2;
  }

  let height = canvas.height;
  const maxHeight = (node.maxDepth + 1) * (options.boxHeight + options.marginY);
  if (maxHeight > height) {
    height = maxHeight;
  }

  canvas.style.width = width + 'px';
  canvas.style.height = height + 'px';
  canvas.style.scale = '0.95';

  const rootPlacement = width / 2 - rootTileWidth / 2;
  const point = {
    x: rootPlacement,
    y: height - options.boxHeight - options.marginY - 110,
  };
  renderNode(node, point);
}

function getEventLocation(e) {
  if (e.touches && e.touches.length === 1) {
    return { x: e.touches[0].clientX, y: e.touches[0].clientY };
  } else if (e.clientX && e.clientY) {
    return { x: e.clientX, y: e.clientY };
  }
}

export let exportedForTesting;

if (import.meta.env.NODE_ENV === 'test') {
  exportedForTesting = {
    createNode,
  };
}
