/* eslint-disable @typescript-eslint/ban-ts-comment */
import { entityTypes } from '@/enums/generic';
import { generateUniqueId } from '@/helpers/uuidUtils';
import { deviceTypeDetails, deviceTypes } from '@/enums/device';
import {
  CaptureEvent,
  DeviceEvent,
  DeviceEventParams,
  MountEvent,
  UnmountEvent,
} from '@/models/installationPoint/DeviceEvent';

import { useEntitiesStore } from '@/store/entities/entitiesStore';
import { DeviceClasses } from '@/models/devices/Device';
import { PlantDataClasses, PlantType } from '@/models/plant/Plant';
import { OverallRadioCheckStatus } from '@/enums/radiocheck';
import { instanceToInstance } from 'class-transformer';
import { LoRaWanRadioCheck } from '@/api/funkcheck/LoRaWanRadioCheck';
import { Adapter } from '@/models/installationPoint/Accessory';

export type DeviceType = (typeof deviceTypes)[keyof typeof deviceTypes];

type Accessory = Adapter;

export type InstallationPointParams = {
  roomId: string;
  radiatorId?: string;
  plantId?: string;
  supportedDeviceType?: DeviceType;
  accessories: Accessory[];
  activeDeviceId?: string;
  billedInRoomGroupId?: string;
  advancedRegistrationById?: string;
  isAdvancedRegistrationMeter?: boolean;
  isCentralAdvancedRegistrationMeter?: boolean;
  advancedRegistrationMeterType?: string;
  deviceEvents?: DeviceEvent[];
  failedInstallationAttempt?: FailedInstallationAttempt;
  ordinal?: number;
  note?: string;
};

export class InstallationPoint {
  id: string;
  type = entityTypes.installationPoint;
  roomId: string;
  radiatorId?: string;
  plantId?: string;
  supportedDeviceType?: DeviceType | PlantType;
  activeDeviceId?: string;
  billedInRoomGroupId?: string;
  accessories: Accessory[];
  advancedRegistrationById?: string;
  isAdvancedRegistrationMeter = false;
  isCentralAdvancedRegistrationMeter = false;
  advancedRegistrationMeterType = undefined;
  deviceEvents: DeviceEvent[];
  failedInstallationAttempt?: FailedInstallationAttempt;
  inspectionData?: InspectionData;
  ordinal = 0;
  note?: string;

  constructor(roomId: string) {
    this.id = generateUniqueId();
    this.roomId = roomId;
    this.radiatorId = undefined;
    this.plantId = undefined;
    this.supportedDeviceType = undefined;
    this.activeDeviceId = undefined;
    this.accessories = [];
    this.deviceEvents = [];
    this.failedInstallationAttempt = undefined;
    this.ordinal = 0;
    this.note = undefined;
    this.billedInRoomGroupId = undefined;
    this.advancedRegistrationById = undefined;
    this.isAdvancedRegistrationMeter = false;
    this.isCentralAdvancedRegistrationMeter = false;
    this.advancedRegistrationMeterType = undefined;
    this.inspectionData = undefined;
  }

  updateProperties(formData: InstallationPoint | InstallationPointParams) {
    const wrongProperties = Object.keys(formData).filter(
      (key) => !Object.keys(this).includes(key)
    );

    if (wrongProperties.length > 0) {
      throw new Error(
        `Provided wrong properties: ${wrongProperties} with ${JSON.stringify(
          formData
        )}`
      );
    }
    Object.assign(this, instanceToInstance(formData));
  }

  getParentId(): string {
    return this.roomId;
  }

  getDeviceType() {
    const deviceType: any = Object.values(deviceTypeDetails).filter(
      (type) => type.value === this.supportedDeviceType
    )[0];
    return deviceType;
  }

  getTagColor() {
    return this.getDeviceType().color;
  }

  getActiveDevice(): DeviceClasses | PlantDataClasses {
    if (!this.activeDeviceId) throw Error('No active device id');
    return useEntitiesStore().getEntityById(this.activeDeviceId);
  }

  getPreviousDevice() {
    const previousDeviceEvent = this.deviceEvents[this.deviceEvents.length - 2];
    if (!previousDeviceEvent) {
      return undefined;
    }
    return useEntitiesStore().getEntityById(previousDeviceEvent?.deviceId);
  }

  getPreviousDeviceEvent() {
    return this.deviceEvents[this.deviceEvents.length - 2];
  }

  setActiveDeviceId(deviceId?: string) {
    this.activeDeviceId = deviceId;
    return this;
  }

  setRadiatorId(radiatorId?: string) {
    this.radiatorId = radiatorId;
    return this;
  }

  setPlantId(plantId?: string) {
    this.plantId = plantId;
    return this;
  }

  setSupportedDeviceType(deviceType: DeviceType | PlantType) {
    this.supportedDeviceType = deviceType;
    return this;
  }

  addFailedInstallationAttempt(
    failedInstallationAttempt: FailedInstallationAttempt,
    supportedDeviceType: DeviceType
  ): this {
    if (this.failedInstallationAttempt) {
      this.failedInstallationAttempt.reason = failedInstallationAttempt.reason;
      this.failedInstallationAttempt.note = failedInstallationAttempt.note;
      return this;
    }
    this.failedInstallationAttempt = new FailedInstallationAttempt();
    this.failedInstallationAttempt.id = failedInstallationAttempt.id;
    this.failedInstallationAttempt.note = failedInstallationAttempt.note;
    this.failedInstallationAttempt.reason = failedInstallationAttempt.reason;
    this.supportedDeviceType = supportedDeviceType;
    return this;
  }

  removeFailedInstallationAttempt(): this {
    this.failedInstallationAttempt = undefined;
    const activeDeviceEvent = this.deviceEvents.find(
      (event) => event instanceof MountEvent
    );
    if (activeDeviceEvent) {
      this.activeDeviceId = activeDeviceEvent.deviceId;
    }
    return this;
  }

  mountDevice(
    deviceId: string,
    timestamp: string,
    counter?: number,
    controlValue?: number
  ) {
    if (!deviceId) {
      throw new Error('DeviceClasses has no deviceId and it cannot be mounted');
    }

    const existingMountEvent = this.deviceEvents.find(
      (event) => event instanceof MountEvent && event.deviceId === deviceId
    );
    if (existingMountEvent) {
      existingMountEvent.deviceId = deviceId;
      existingMountEvent.timestamp = timestamp;
      existingMountEvent.counter = counter;
      return;
    }

    this.deviceEvents.push(
      new MountEvent(deviceId, timestamp, counter, controlValue)
    );
    this.setActiveDeviceId(deviceId);
  }

  unmountDevice(
    deviceId: string,
    timestamp: string,
    counter?: number,
    controlValue?: number,
    reason?: string
  ) {
    if (!deviceId) {
      throw new Error(
        'DeviceClasses has no deviceId and it cannot be unmounted'
      );
    }

    const existingUnmountEvent: UnmountEvent | undefined =
      this.deviceEvents.find(
        (event) => event instanceof UnmountEvent && event.deviceId === deviceId
      );
    if (existingUnmountEvent) {
      existingUnmountEvent.deviceId = deviceId;
      existingUnmountEvent.timestamp = timestamp;
      existingUnmountEvent.counter = counter;
      existingUnmountEvent.reason = reason;
      if (controlValue) {
        existingUnmountEvent.controlValue = controlValue;
      }
      return;
    }

    this.deviceEvents.push(
      new UnmountEvent(deviceId, timestamp, counter, controlValue, reason)
    );
    this.setActiveDeviceId(undefined);
  }

  captureDevice(
    deviceId: string,
    timestamp: string,
    counter?: number,
    controlValue?: number
  ) {
    if (!deviceId) {
      throw new Error(
        'DeviceClasses has no deviceId and it cannot be unmounted'
      );
    }

    const existingCaptureEvent: CaptureEvent | undefined =
      this.deviceEvents.find(
        (event) => event instanceof CaptureEvent && event.deviceId === deviceId
      );
    if (existingCaptureEvent) {
      existingCaptureEvent.deviceId = deviceId;
      existingCaptureEvent.timestamp = timestamp;
      existingCaptureEvent.counter = counter;
      if (controlValue) {
        existingCaptureEvent.controlValue = controlValue;
      }
      return;
    }

    this.deviceEvents.push(
      new CaptureEvent(deviceId, timestamp, counter, controlValue)
    );
    this.setActiveDeviceId(deviceId);
  }

  replaceDevice(
    device: DeviceClasses,
    deviceEvent: DeviceEvent,
    oldDevice: DeviceClasses,
    oldDeviceEvent: DeviceEventParams
  ) {
    const eventDate = new Date(oldDeviceEvent?.timestamp);
    const oneSecondBefore = new Date(eventDate.getTime() - 1000);
    this.unmountDevice(
      oldDevice?.id,
      oneSecondBefore.toISOString(),
      oldDeviceEvent?.counter,
      oldDeviceEvent?.controlValue,
      oldDeviceEvent?.reason
    );
    if (this.failedInstallationAttempt === undefined) {
      this.mountDevice(
        device?.id,
        deviceEvent?.timestamp,
        deviceEvent?.counter
      );
    }
  }

  unsetActiveDevice() {
    this.activeDeviceId = undefined;
    this.deviceEvents = [];
  }

  recaptureDevice(device: DeviceClasses, counterOnCapture: number) {
    const timestamp = new Date().toISOString();
    this.captureDevice(device?.id, timestamp, counterOnCapture);
  }

  addInspectionData(inspectionData: InspectionData) {
    this.inspectionData = inspectionData;
  }
}

export class FailedInstallationAttempt {
  id: string;
  timestamp: string;
  reason: string;
  note: string;

  constructor() {
    this.id = generateUniqueId();
    this.timestamp = new Date().toISOString();
    this.reason = '';
    this.note = '';
  }

  update(id: string, reason: string, note: string) {
    this.id = id;
    this.reason = reason;
    this.note = note;
  }
}

export class InspectionData {
  lteReception?: number;
  lteReceptionDbm?: number;
  radioCheckResult?: OverallRadioCheckStatus;
  radioChecks: Array<LoRaWanRadioCheck>;
  connectionOptions: Array<string>;
  connectionAvailable: boolean;
  cableLength?: number;

  constructor() {
    this.lteReception = undefined;
    this.lteReceptionDbm = undefined;
    this.radioCheckResult = undefined;
    this.radioChecks = [];
    this.connectionOptions = [];
    this.connectionAvailable = true;
    this.cableLength = undefined;
  }

  setRadioCheckResult(
    result: LoRaWanRadioCheck,
    runId: number,
    startedAt?: string,
    updatedAt?: string
  ) {
    const radioCheckResult = {
      status: result.status,
      runId,
      startedAt,
      updatedAt,
      result: result.result,
    };
    this.radioCheckResult = result.status;
    this.radioChecks.push(radioCheckResult);
  }
}
