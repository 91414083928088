<template>
  <div
    ref="sideMenu"
    class="h-full w-full overflow-y-auto p-5 pb-28 shadow md:p-10 md:px-10 md:pt-8"
  >
    <div class="flex flex-row justify-between">
      <div class="flex flex-col">
        <h4 v-if="selectedOrder.workTypeCode" class="text-xl">
          {{ selectedOrder?.workTypeCode }}
        </h4>
        <h4
          v-if="selectedOrder.propertyId || selectedOrder.billingUnitReference"
          data-testid="activeOrderSubtitle"
          class="text-sm font-normal"
        >
          {{ selectedOrder.billingUnitReference }} /
          {{ selectedOrder.propertyId }}
        </h4>
        <h2>Auftragsübersicht</h2>
      </div>
      <div class="flex md:hidden">
        <em
          class="mdi mdi-close cursor-pointer text-3xl"
          @click="emits('update:selectedOrder', undefined)"
        ></em>
      </div>
    </div>

    <div
      v-if="!isOrderClosed"
      class="fixed bottom-8 right-10 z-10 flex justify-between"
    >
      <ScrollButton
        button-text="Auftrag starten"
        alternative-button-text="Auftrag fortführen"
        :show-alternative-button-text="isOrderInProgress"
        button-icon="arrow-right-thick"
        data-testid="button-start-order"
        @on-click="goToOrder"
      />
    </div>

    <div class="grid grid-flow-row grid-cols-1 gap-5 md:grid-cols-2">
      <div class="flex rounded-xl bg-light-2 py-5 pr-5 shadow dark:bg-dark-2">
        <div class="flex items-center px-5">
          <em class="mdi mdi-map-marker text-3xl"></em>
        </div>

        <div class="flex grow flex-col" data-testid="orderAddress">
          <div>{{ streetAndHouseNumber }}</div>
          <div>{{ zipCodeAndCity }}</div>
        </div>

        <div
          class="flex cursor-pointer flex-col items-center justify-center"
          @click="openMaps"
        >
          <div
            class="flex h-8 w-8 items-center justify-center rounded-full bg-primary-1 text-white hover:bg-primary-2"
          >
            <em class="mdi mdi-navigation"></em>
          </div>
        </div>
      </div>

      <div class="flex rounded-xl bg-light-2 py-5 shadow dark:bg-dark-2">
        <div class="flex items-center px-5">
          <em class="mdi mdi-phone text-3xl"></em>
        </div>

        <div
          v-if="
            !selectedOrder.contactPerson?.name &&
            !selectedOrder.contactPerson?.phoneNumber
          "
          class="flex items-center text-neutral-400"
        >
          Keine Kontaktperson angegeben
        </div>
        <div class="flex flex-col" data-testid="orderContactPerson">
          <div>{{ selectedOrder.contactPerson?.name }}</div>
          <div v-if="selectedOrder.contactPerson?.role">
            {{ getRoleDisplayName(selectedOrder.contactPerson?.role) }}
          </div>
          <div>{{ selectedOrder.contactPerson?.phoneNumber }}</div>
        </div>
      </div>

      <div class="flex rounded-xl bg-light-2 py-5 shadow dark:bg-dark-2">
        <div class="flex items-center px-5">
          <em class="mdi mdi-calendar-clock text-3xl"></em>
        </div>

        <div class="flex-col">
          <div>{{ formattedDate }}</div>
          <div>{{ formattedStartAndEndTime }}</div>
        </div>
      </div>

      <div class="flex rounded-xl bg-light-2 py-5 shadow dark:bg-dark-2">
        <div class="flex items-center px-5">
          <em class="mdi mdi-warehouse text-3xl"></em>
        </div>

        <div class="flex flex-col self-center">
          <div v-if="selectedOrder.locationReference">
            Leitzahl: {{ selectedOrder.locationReference }}
          </div>
          <div v-else>Keine Leitzahl angegeben</div>
        </div>
      </div>
    </div>

    <h3>Artikelliste</h3>
    <div class="rounded-xl bg-light-2 px-5 py-1 shadow dark:bg-dark-2 md:px-5">
      <MaterialTable :material-list="selectedOrder.articles.items" />
    </div>

    <h3>Serviceliste</h3>
    <div class="rounded-xl bg-light-2 px-5 py-1 shadow dark:bg-dark-2 md:px-5">
      <ServicesTable :service-list="selectedOrder.serviceGroups" />
    </div>

    <h3>Notizen</h3>
    <div class="rounded-xl bg-light-2 px-5 py-5 shadow dark:bg-dark-2 md:px-5">
      <div
        v-if="selectedOrder.notes?.length > 0"
        class="flex-col whitespace-pre-line"
        data-testid="orderNotes"
      >
        {{ selectedOrder.notes }}
      </div>
      <div v-else>Notizen sind leer</div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, provide, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useLayoutStore } from '@/store/layout/layoutStore';
import { useEntitiesStore } from '@/store/entities/entitiesStore';
import { useOrderStore } from '@/store/order/orderStore';
import { Order } from '@/models/Order';
import { OrderStatus } from '@/models/types/OrderStatus';
import MaterialTable from '@/components/OrderManagement/MaterialTable.vue';
import ServicesTable from '@/components/OrderManagement/ServicesTable.vue';
import { getRoleDisplayName } from '@/models/types/ContactPersonRole';
import ScrollButton from '@/components/UI/Button/ScrollButton.vue';
import { usePersistenceStore } from '@/store/persistence/persistenceStore';

const router = useRouter();

const emits = defineEmits(['update:selectedOrder']);

const props = defineProps<{
  selectedOrder: Order;
}>();

const isScrolled = ref(false);
provide('isScrolled', isScrolled);
const sideMenu = ref<HTMLElement | undefined>(undefined);

onMounted(() => {
  sideMenu?.value?.addEventListener('scroll', checkScroll);
});

const checkScroll = () => {
  isScrolled.value = (sideMenu?.value?.scrollTop as number) > 100;
};

const goToOrder = () => {
  const entitiesStore = useEntitiesStore();
  const orderStore = useOrderStore();
  const layoutStore = useLayoutStore();
  const persistenceStore = usePersistenceStore();

  orderStore.startOrder(props.selectedOrder.id);
  persistenceStore.resetErrorStatus();

  entitiesStore
    .setActiveBusinessEntityById(props.selectedOrder.businessEntityId)
    .then(() => {
      router.push('/gebaeudeuebersicht');
      if (entitiesStore.activeBusinessEntity) {
        layoutStore.changeCurrentNode(entitiesStore.activeBusinessEntity);
      }
    })
    .catch((error) => {
      console.error(error);
    });
};

const isOrderInProgress = computed(() => {
  return props.selectedOrder.status === OrderStatus.IN_PROGRESS;
});

const isOrderClosed = computed(() => {
  return props.selectedOrder.status === OrderStatus.CLOSED;
});

const formattedDate = computed(() => {
  const date = props.selectedOrder.appointment.start;
  return new Date(date).toLocaleDateString('de-DE', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
});

const formattedStartAndEndTime = computed(() => {
  const startTime = new Date(
    props.selectedOrder.appointment.start
  ).toLocaleTimeString('de-DE', {
    hour: '2-digit',
    minute: '2-digit',
  });
  const endTime = new Date(
    props.selectedOrder.appointment.end
  ).toLocaleTimeString('de-DE', {
    hour: '2-digit',
    minute: '2-digit',
  });
  return `${startTime} - ${endTime} Uhr`;
});

const streetAndHouseNumber = computed(() => {
  return (
    props.selectedOrder.primaryAddress.street +
    ' ' +
    props.selectedOrder.primaryAddress.houseNumber
  );
});

const zipCodeAndCity = computed(() => {
  return (
    props.selectedOrder.primaryAddress.zipCode +
    ' ' +
    props.selectedOrder.primaryAddress.city
  );
});

const mapsUrl =
  'https://www.google.com/maps/dir/?api=1&destination=' +
  streetAndHouseNumber.value +
  ', ' +
  zipCodeAndCity.value;
const openMaps = () => {
  window.open(mapsUrl, '_blank');
};
</script>
