import { useEntitiesStore } from '@/store/entities/entitiesStore';
import {
  putBusinessEntity,
  putRawBusinessEntity,
} from '@/api/businessEntity/BusinessEntityApi';
import { convertTreeToFlat } from '@/utils/transformers';
import { useNotificationStore } from '@/store/notifications/notificationStore';
import { Pinia, Store } from '@/store/pinia-class-component';
import { putImage } from '@/api/pictures/PicturesApi';
import { putOrderProgress } from '@/api/order/OrderManagement';
import { useOrderStore } from '@/store/order/orderStore';
import { Image } from '@/models/Image';
import { Entity } from '@/models/types/Entity';
import { Order, OrderType } from '@/models/Order';

type errorStatus = {
  hasError: boolean;
  error: string;
};

export function usePersistenceStore() {
  return new PersistenceStore();
}

@Store
export class PersistenceStore extends Pinia {
  private _errorStatus: errorStatus;

  constructor() {
    super();
    this._errorStatus = {
      hasError: false,
      error: '',
    };
  }

  get errorStatus(): errorStatus {
    return this._errorStatus;
  }

  getStores() {
    const entitiesStore = useEntitiesStore();
    const notificationStore = useNotificationStore();
    const orderStore = useOrderStore();

    return {
      entitiesStore,
      notificationStore,
      orderStore,
    };
  }

  get saved(): boolean {
    return !useOrderStore().hasUnsavedOrderChanges;
  }

  updateSaveSuccess() {
    useOrderStore().updateLastSavedOnActiveOrder();
  }

  updateSaveFailed(error: any) {
    this._errorStatus.hasError = true;
    this._errorStatus.error = error;
  }

  resetErrorStatus() {
    this._errorStatus.hasError = false;
    this._errorStatus.error = '';
  }

  async saveImage(image: Image) {
    return await putImage(image);
  }

  async saveData() {
    const rawDataMap = this.getStores().entitiesStore.businessEntityMap;
    const dataMap = convertTreeToFlat(rawDataMap);
    const selectedOrder = this.getStores().orderStore?.selectedOrder;

    await this.backupRawData(rawDataMap);

    const isSaved = await this.saveBusinessEntity(
      dataMap,
      selectedOrder?.workTypeCode
    );
    if (!isSaved)
      return Promise.reject(new Error('Business entity save failed'));

    if (selectedOrder) {
      const orderProgressSuccess = await this.saveOrderProgress(selectedOrder);
      if (!orderProgressSuccess)
        return Promise.reject(new Error('Order progress save failed'));
    }

    return Promise.resolve(isSaved && selectedOrder);
  }

  async backupRawData(rawDataMap: Map<string, Entity>) {
    try {
      await putRawBusinessEntity(rawDataMap);
      return true;
    } catch (error) {
      console.error(`Raw Save failed: ${error}`);
      return false;
    }
  }

  async saveBusinessEntity(
    dataMap: Record<string, any>,
    workTypeCode: OrderType | undefined
  ) {
    try {
      await putBusinessEntity(dataMap, workTypeCode);
      this.updateSaveSuccess();
      this.resetErrorStatus();
      return true;
    } catch (error) {
      this.updateSaveFailed(error);
      console.error(`Business entity save failed: ${error}`);
      return false;
    }
  }

  async saveOrderProgress(selectedOrder: Order) {
    if (!selectedOrder) return false;
    try {
      await putOrderProgress(selectedOrder);
      return true;
    } catch (error) {
      console.error(`Order progress save failed: ${error}`);
      return false;
    }
  }
}
