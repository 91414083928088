<template>
  <div
    class="prose prose-neutral flex flex-col bg-light-2 dark:prose-invert dark:bg-dark-2 md:flex-row"
  >
    <div
      class="fixed w-full bg-light-1 shadow dark:bg-dark-1 dark:text-white md:relative md:w-auto md:basis-12"
    >
      <SideNavBar />
    </div>
    <div
      class="flex h-screen grow flex-col bg-light-0 p-10 pt-24 dark:bg-dark-0 md:w-1/2 md:pt-10"
    >
      <div class="flex flex-row justify-between">
        <h1 class="flex-col">Alle Wirtschaftseinheiten</h1>
        <div class="flex-col">
          <button
            class="button has-text-weight-bold"
            data-testid="fetchEntitiesBtn"
            @click="fetchAllBusinessEntities"
          >
            <em class="fa-solid fa-arrows-rotate mr-2"></em> Refresh
          </button>
        </div>
      </div>

      <div class="overflow-auto rounded-xl bg-light-1 p-5 dark:bg-dark-1">
        <table class="m-0" aria-label="BusinessEntityList">
          <thead class="uppercase">
            <tr>
              <th @click="sortColumn('billingUnitReference')">
                Billing Unit Ref<em
                  :class="[
                    sortOrder === 1
                      ? 'mdi mdi-chevron-up'
                      : 'mdi mdi-chevron-down',
                    sortKey === 'billingUnitReference'
                      ? 'opacity-100'
                      : 'opacity-0',
                  ]"
                />
              </th>
              <th @click="sortColumn('address.zipCode')">
                PLZ<em
                  :class="[
                    sortOrder === 1
                      ? 'mdi mdi-chevron-up'
                      : 'mdi mdi-chevron-down',
                    sortKey === 'address.zipCode' ? 'opacity-100' : 'opacity-0',
                  ]"
                />
              </th>
              <th @click="sortColumn('address.city')">
                Ort<em
                  :class="[
                    sortOrder === 1
                      ? 'mdi mdi-chevron-up'
                      : 'mdi mdi-chevron-down',
                    sortKey === 'address.city' ? 'opacity-100' : 'opacity-0',
                  ]"
                />
              </th>
              <th @click="sortColumn('address.street')">
                Straße<em
                  :class="[
                    sortOrder === 1
                      ? 'mdi mdi-chevron-up'
                      : 'mdi mdi-chevron-down',
                    sortKey === 'address.street' ? 'opacity-100' : 'opacity-0',
                  ]"
                />
              </th>
              <th @click="sortColumn('address.houseNumber')">
                Hausnummer<em
                  :class="[
                    sortOrder === 1
                      ? 'mdi mdi-chevron-up'
                      : 'mdi mdi-chevron-down',
                    sortKey === 'address.houseNumber'
                      ? 'opacity-100'
                      : 'opacity-0',
                  ]"
                />
              </th>
              <th @click="sortColumn('contactPerson.name')">
                Ansprechpartner<em
                  :class="[
                    sortOrder === 1
                      ? 'mdi mdi-chevron-up'
                      : 'mdi mdi-chevron-down',
                    sortKey === 'contactPerson.name'
                      ? 'opacity-100'
                      : 'opacity-0',
                  ]"
                />
              </th>
              <th @click="sortColumn('contactPerson.phoneNumber')">
                Telefon<em
                  :class="[
                    sortOrder === 1
                      ? 'mdi mdi-chevron-up'
                      : 'mdi mdi-chevron-down',
                    sortKey === 'contactPerson.phoneNumber'
                      ? 'opacity-100'
                      : 'opacity-0',
                  ]"
                />
              </th>
              <th @click="sortColumn('propertyId')">
                Property Id<em
                  :class="[
                    sortOrder === 1
                      ? 'mdi mdi-chevron-up'
                      : 'mdi mdi-chevron-down',
                    sortKey === 'propertyId' ? 'opacity-100' : 'opacity-0',
                  ]"
                />
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="businessEntity in sortedBusinessEntities"
              :key="businessEntity.id"
              class="cursor-pointer p-3 hover:bg-light-2 hover:dark:bg-dark-2"
              @click="(event) => loadBusinessEntity(event, businessEntity.id)"
            >
              <td>
                {{ businessEntity.billingUnitReference }}
              </td>
              <td>
                {{ businessEntity.address?.zipCode }}
              </td>
              <td>
                {{ businessEntity.address?.city }}
              </td>
              <td>
                {{ businessEntity.address?.street }}
              </td>
              <td>
                {{ businessEntity.address?.houseNumber }}
              </td>
              <td>
                {{ businessEntity.contactPerson?.name }}
              </td>
              <td>
                {{ businessEntity.contactPerson?.phoneNumber }}
              </td>

              <td>
                {{ businessEntity.propertyId }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { useFormStore } from '@/store/form/formStore';
import { useEntitiesStore } from '@/store/entities/entitiesStore';
import { useNotificationStore } from '@/store/notifications/notificationStore';
import classTransformer from '@/utils/transformers/classTransformer';
import SideNavBar from '@/components/SideMenu/SideNavBar.vue';
import { usePersistenceStore } from '@/store/persistence/persistenceStore';
import { useLayoutStore } from '@/store/layout/layoutStore';

export default {
  name: 'ListView',
  components: { SideNavBar },

  setup() {
    const formStore = useFormStore();
    const entitiesStore = useEntitiesStore();
    const notificationStore = useNotificationStore();
    const layoutStore = useLayoutStore();

    return {
      formStore,
      entitiesStore,
      notificationStore,
      layoutStore,
    };
  },
  data() {
    return {
      isOpen: undefined,
      yesNoLoadModal: false,
      currentBusinessEntity: {},
      sortKey: 'humanReadableId',
      sortOrder: 1,
    };
  },
  computed: {
    sortedBusinessEntities() {
      const businessEntitiesArray = [];
      this.entitiesStore.businessEntities.forEach((item) => {
        businessEntitiesArray.push(item.values().next().value);
      });
      return businessEntitiesArray.sort((a, b) => {
        const splitSortKey = this.sortKey.split('.');
        if (!splitSortKey[1]) {
          return (
            this.sortOrder *
            a[splitSortKey[0]].localeCompare(b[splitSortKey[0]])
          );
        }

        return (
          this.sortOrder *
          a[splitSortKey[0]][splitSortKey[1]]?.localeCompare(
            b[splitSortKey[0]][splitSortKey[1]]
          )
        );
      });
    },
  },
  mounted() {
    this.entitiesStore.fetchAllBusinessEntities();
  },
  methods: {
    sortColumn(key) {
      if (this.sortKey !== key) {
        this.sortKey = key;
        this.sortOrder = 1;
        return;
      }

      this.sortOrder = this.sortOrder * -1;
    },
    openDropdown(event, id) {
      event.preventDefault();
      event.stopPropagation();
      this.isOpen = id;
    },
    closeDropdown() {
      this.isOpen = undefined;
    },
    fetchAllBusinessEntities() {
      this.entitiesStore.fetchAllBusinessEntities();
    },
    loadBusinessEntity(event, businessEntityId) {
      this.entitiesStore
        .setActiveBusinessEntityById(businessEntityId)
        .then(() => {
          this.loadBusinessEntityFromAPI(event, businessEntityId);
        });
    },
    loadBusinessEntityFromAPI(event, businessEntityId) {
      event.preventDefault();
      event.stopPropagation();
      this.entitiesStore
        .loadBusinessEntityFromAPI(businessEntityId)
        .then((fromAPI) => {
          this.entitiesStore._activeBusinessEntityId = businessEntityId;
          const businessEntityMap =
            classTransformer.plainToClassEntity(fromAPI);
          this.entitiesStore.resetMiniEntityStores();
          this.entitiesStore.setBusinessEntityList(businessEntityMap);
          this.entitiesStore.setBusinessEntityMap(businessEntityMap);
          this.$router.push('/gebaeudeuebersicht');
          if (this.entitiesStore.activeBusinessEntity) {
            this.layoutStore.changeCurrentNode(
              this.entitiesStore.activeBusinessEntity
            );
          }
        });
    },
  },
};
</script>
