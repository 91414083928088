import { generateUniqueId } from '@/helpers/uuidUtils';
import { entityTypes } from '@/enums/generic';

export enum PlantType {
  HEATING_PLANT = 'HEATING_PLANT',
  WATER_PLANT = 'WATER_PLANT',
}

export type PlantTypes = 'HEATING_PLANT' | 'WATER_PLANT';

export interface PlantTypeDetails {
  value: PlantType;
  label: string;
  shortLabel: string;
  color: string;
}

export const plantTypeDetails: Record<string, PlantTypeDetails> = {
  heatingPlant: {
    value: 'HEATING_PLANT',
    label: 'Heizkessel',
    shortLabel: 'HK',
    color: '#9115fa',
  },
  waterPlant: {
    value: 'WATER_PLANT',
    label: 'Wasseranlage',
    shortLabel: 'WA',
    color: '#0039f5',
  },
};

export type PlantClasses = HeatingPlant | WaterPlant;

export type PlantParams = {
  roomId: string;
  plantType: PlantType;
  note: string;
};
export abstract class Plant {
  public id: string;
  public type: string;
  public plantType: PlantType;
  public roomId?: string;
  public note?: string;

  protected constructor(params: PlantParams) {
    this.id = generateUniqueId();
    this.type = entityTypes.plant;
    this.plantType = params?.plantType ?? undefined;
    this.roomId = params?.roomId ?? undefined;
    this.note = params?.note ?? undefined;
  }

  updateProperties<T extends PlantClasses>(formData: T) {
    const wrongProperties = Object.keys(formData).filter(
      (key) => !Object.keys(this).includes(key)
    );

    if (wrongProperties.length > 0) {
      throw new Error(
        `Provided wrong properties: ${wrongProperties} with ${JSON.stringify(
          formData
        )}`
      );
    }
    Object.assign(this, formData);
  }

  setParentRoomId(roomId: string) {
    this.roomId = roomId;
  }

  getParentId(): string | undefined {
    return this.roomId;
  }

  getPlantType() {
    const plantType: any = Object.values(plantTypeDetails).filter(
      (type) => type.value === this.plantType
    )[0];
    return plantType;
  }

  isHeatingPlant() {
    return this.plantType === PlantType.HEATING_PLANT;
  }

  isWaterPlant() {
    return this.plantType === PlantType.WATER_PLANT;
  }

  getLabel() {
    return this.getPlantType().shortLabel;
  }

  getTagColor() {
    return this.getPlantType().color;
  }
}

export class HeatingPlant extends Plant {
  public temperatureSettings: Array<TemperatureSetting>;

  constructor(params?: any) {
    super(params);
    this.plantType = PlantType.HEATING_PLANT;
    this.temperatureSettings = [];
  }
}

export class WaterPlant extends Plant {
  constructor(params?: any) {
    super(params);
    this.plantType = PlantType.WATER_PLANT;
  }
}

export type PlantDataClasses = HeatingPlant | WaterPlant;

export class TemperatureSetting {
  public id: string;
  public ingressTemperature?: number;
  public startDate?: string;
  public endDate?: string;

  constructor() {
    this.id = generateUniqueId();
    this.ingressTemperature = undefined;
    this.startDate = new Date().toISOString().substr(0, 10);
    this.endDate = undefined;
  }
}

type PlantClassMap = {
  HEATING_PLANT: HeatingPlant;
  WATER_PLANT: WaterPlant;
};

export class PlantFactory {
  static createPlant<T extends PlantTypes>(type: T): PlantClassMap[T] {
    switch (type) {
      case PlantType.HEATING_PLANT:
        return new HeatingPlant() as PlantClassMap[T];
      case PlantType.WATER_PLANT:
        return new WaterPlant() as PlantClassMap[T];
      default:
        throw new Error(`Unsupported plant type: ${type}`);
    }
  }
}
