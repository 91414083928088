import { default as envConfig } from '../config/runtimeConfig.js';

// Generic data not specific to one component
export const entityTypes = Object.freeze({
  advRegMeter: 'advRegMeter',
  building: 'building',
  inspectedBuilding: 'inspectedBuilding',
  businessEntity: 'businessEntity',
  device: 'device',
  radiator: 'radiator',
  room: 'room',
  installationPoint: 'installationPoint',
  roomgroup: 'roomgroup',
  centralWarmWaterHeatMeter: 'centralWarmWaterHeatMeter',
  plant: 'plant',
});

export const boolOptionsList = Object.freeze([
  { name: 'Ja', value: true },
  { name: 'Nein', value: false },
]);

export const apiErrorCodes = Object.freeze({
  RESOURCE_NOT_FOUND: 'Entity Not found',
});

export const sideBarWidth = Object.freeze({
  radiator: 10,
  RadiatorList: 10,
  DeviceList: 10,
  device: 10,
  Funkcheck: 10,
});

export const listingForms = Object.freeze({
  device: 'DeviceList',
  radiator: 'RadiatorList',
});

export const apiEndpoint = Object.freeze({
  businessEntitiesUrl: `${envConfig.backendBaseUrl}/business-entities`,
  assignedOrdersUrl: `${envConfig.backendBaseUrl}/orders/assigned-to-me`,
  radiatorRatingsUrl: `${envConfig.backendBaseUrl}/radiators/identification`,
  statusOrderUrl: `${envConfig.backendBaseUrl}/orders`,
  telemetryUrl: `${envConfig.backendBaseUrl}/telemetry/events`,
});

export enum Status {
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  PENDING = 'pending',
}
