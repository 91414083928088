import { Action, Notification, Room } from '@/models';
import { getAllEntitiesByCriteria } from '@/store/entities/helper';
import { useDeviceStore } from '@/store/entities/deviceStore';
import { Entity } from '@/models/types/Entity';
import { DeviceClasses } from '@/models/devices/Device';
import { Tag } from '@/utils/canvas/drawingUtils';
import { entityTypes } from '@/enums/generic';
import { useEntitiesStore } from '@/store/entities/entitiesStore';
import { useFormStore } from '@/store/form/formStore';
import { Form } from '@/models/Form';
import { useRoomGroupStore } from '@/store/entities/roomGroupStore';
import { Store } from '@/store/pinia-class-component';
import { useLayoutStore } from '@/store/layout/layoutStore';
import { miniEntityStore } from '@/store/entities/types/miniEntityStore';
import { useFunkcheckStore } from '@/store/funkcheck/funkcheckStore';
import { useInstallationPointStore } from '@/store/entities/installationPointStore';
import { NO_SIGNAL_ICON } from '@/enums/icons';
import { usePlantStore } from '@/store/entities/plantStore';
import { PlantDataClasses } from '@/models/plant/Plant';

export function useRoomStore() {
  return new RoomStore();
}

@Store
export class RoomStore extends miniEntityStore {
  private _rooms: Map<string, Room>;

  constructor() {
    super();
    this._rooms = new Map();
  }

  get rooms(): Map<string, Room> {
    return this._rooms;
  }

  getStores() {
    const deviceStore = useDeviceStore();
    const entitiesStore = useEntitiesStore();
    const roomGroupStore = useRoomGroupStore();
    const installationPointStore = useInstallationPointStore();
    const layoutStore = useLayoutStore();
    const plantStore = usePlantStore();

    return {
      deviceStore,
      entitiesStore,
      roomGroupStore,
      layoutStore,
      installationPointStore,
      plantStore,
    };
  }

  addEntity(newRoom: Room): void {
    this._rooms.set(newRoom.id, newRoom);
  }

  resetState() {
    this._rooms = new Map();
  }

  getRoomsByRoomGroupId(roomGroupId: string): Array<Room> {
    return getAllEntitiesByCriteria(
      this._rooms,
      (room: Room) => room.roomGroupId === roomGroupId
    );
  }

  getChildrenOfRoom(roomId: string): Array<Room> {
    return getAllEntitiesByCriteria(
      this._rooms,
      (room: Room) => room.parentRoomId === roomId
    );
  }

  getFirstRoomOfRoomgroup(roomgroupId: string): Room | undefined {
    const roomsOfRoomgroup = this.getRoomsByRoomGroupId(roomgroupId);
    // Find the room whose parent belongs to a different roomgroup
    return roomsOfRoomgroup.find(
      (room) =>
        (
          this.getStores().entitiesStore.getEntityById(
            room.getParentId() as string
          ) as Room
        ).roomGroupId !== roomgroupId
    );
  }

  getNewRoomOrdinal(parentId: string | undefined): number {
    if (parentId === undefined) {
      return 0;
    }
    const children = getAllEntitiesByCriteria(
      this._rooms,
      (room: Room) => room.getParentId() === parentId
    );
    return children.length + 1;
  }

  getRoomBadgeIcon(roomId: string): Array<string> {
    const room = this._rooms.get(roomId);
    const notificationIcons = [];

    let roomHasNotDevice = false;

    const allInstallationPoints =
      this.getStores().installationPointStore.getInstallationPointsByRoomId(
        roomId
      );
    roomHasNotDevice = allInstallationPoints.some(
      (installationPoint) => installationPoint.failedInstallationAttempt
    );
    if (roomHasNotDevice || !room?.isAccessible)
      notificationIcons.push('mdi-alert-circle');

    const hasFunkcheckError =
      useFunkcheckStore().funkcheckErrors.filter((error) => {
        return error.roomId === room?.id;
      }).length > 0;

    if (hasFunkcheckError) notificationIcons.push(NO_SIGNAL_ICON);

    return notificationIcons;
  }

  getDeviceAndPlantTagsOfRoom(roomId: string): Array<Tag> {
    const tags: Array<Tag> = [];
    const devices = this.getStores().deviceStore.getAllDevicesInRoom(roomId);
    const plants = this.getStores().plantStore.getAllPlantsInRoom(roomId);

    devices.filter(
      (device: DeviceClasses) => device.getLabel() !== 'Gerätetyp'
    );

    const devicesAndPlants = [...devices, ...plants];

    const labels = new Set(
      devicesAndPlants.map((entity: DeviceClasses | PlantDataClasses) => {
        return entity.getLabel();
      })
    );

    labels.forEach((label) => {
      const devicesByLabel = devicesAndPlants.filter(
        (entity: DeviceClasses | PlantDataClasses) =>
          entity.getLabel() === label
      );
      const color = devicesByLabel[0].getTagColor();
      tags.push(new Tag(label, devicesByLabel.length, color));
    });
    return tags;
  }

  getRoomActions(room: Room) {
    if (room.type !== entityTypes.room) {
      return [];
    }

    const formStore = useFormStore();

    const actions = [
      /*  new Action(
        'Heizkörper bearbeiten',
        () => formStore.showCurrentFormList('radiators'),
        'fas fa-angle-double-down',
        new Form().setName('radiators').setParentId(room.id)
      ), */
      new Action(
        'Gerät hinzufügen',
        formStore.createEntity,
        'fas fa-mobile-alt',
        new Form().setParentId(room.id).setName(entityTypes.installationPoint)
      ),
      new Action(
        'Raum hinzufügen',
        formStore.createEntity,
        'far fa-plus',
        new Form().setParentId(room.id).setName(room.type)
      ),

      /*  new Action('Raum bearbeiten', formStore.editRoom, 'far fa-square', room), */
    ];

    const entitiesStore = useEntitiesStore();
    if (
      entitiesStore.getAllEntitiesByParentId(room.id).length === 0 &&
      room.roomGroupId !== undefined &&
      this.getFirstRoomOfRoomgroup(room.roomGroupId)?.id !== room.id
    ) {
      actions.push(
        new Action(
          'Raum löschen',
          () => entitiesStore.deleteEntity(room, new Notification()),
          'fas fa-trash-alt',
          room
        )
      );
      return actions;
    }

    const hasDevices =
      this.getStores().installationPointStore.getInstallationPointsByParentId(
        room.id
      ).length > 0;

    if (hasDevices) {
      /*     actions.unshift(
        new Action(
          'inst. Geräte bearbeiten',
          () => formStore.showCurrentFormList('devices'),
          'fas fa-gear'
        )
      ); */
    }
    return actions;
  }

  swapRoom(entity: Room) {
    if (this._rooms.get(entity.id) === undefined) {
      throw new Error('Room not found');
    }

    // get sibling to be swapped
    const sibling = getAllEntitiesByCriteria(
      this._rooms,
      (room: Room) =>
        room.parentRoomId === entity.parentRoomId &&
        room.ordinal === entity.ordinal - 1
    )[0];

    // rooms whose ordinals should be swapped
    const tempOrdinal = entity.ordinal;
    entity.ordinal = sibling.ordinal;
    sibling.ordinal = tempOrdinal;

    this.getStores().layoutStore.changeCurrentNode(
      this.getStores().entitiesStore.getParentOfEntity(entity.id) as Entity
    );

    this.getStores().entitiesStore.saveEntity(sibling);
    this.getStores().entitiesStore.saveEntity(entity);
  }

  isDeletable(room: Room): boolean {
    return (
      useEntitiesStore().getAllEntitiesByParentId(room.id).length === 0 &&
      room.roomGroupId !== undefined &&
      this.getFirstRoomOfRoomgroup(room.roomGroupId)?.id !== room.id
    );
  }

  deleteEntity(entity: Room) {
    if (this._rooms.get(entity.id) === undefined) {
      throw new Error('Room not found');
    }

    // Check if no devices or radiators
    if (
      this.getStores().entitiesStore.getAllEntitiesByParentId(entity.id)
        .length > 0
    ) {
      throw new Error(
        `Cannot delete an a room that has devices or radiators, children: ${
          this.getStores().entitiesStore.getAllEntitiesByParentId(entity.id)
            .length
        }`
      );
    }

    const parentRoom = this.getStores().entitiesStore.getParentOfEntity(
      entity.id
    ) as Room;
    const roomGroup = this.getStores().roomGroupStore.roomGroups.get(
      entity.roomGroupId as string
    );

    this.getStores().layoutStore.changeCurrentNode(parentRoom);
    // If is first room in the roomgroup, delete roomgroup aswell
    this._rooms.delete(entity.id);
  }

  isRoomSuccessfulInstallationLocation(roomId: string): boolean {
    return (
      this.getStores().installationPointStore.getInspectionPlaceholders({
        roomId: roomId,
      }).length > 0
    );
  }
}
