<template>
  <div data-testId="deviceList">
    <div class="flex flex-col gap-10">
      <div
        v-if="
          Object.values(deviceTypesWithDevices).length === 0 &&
          Object.values(noMountingDevices).length === 0
        "
      >
        <BaseInformation
          status="info"
          information-text="In diesem Raum sind noch keine Geräte installiert."
        />
      </div>

      <DeviceTypeTable
        v-for="(value, key) in noMountingDevices"
        :key="key"
        :devices="value"
        :device-type="key"
        :on-edit="editDevice"
        :on-delete="openDeleteDeviceConfirmationModal"
        :on-exchange="exchangeDevice"
      />
      <DeviceTypeTable
        v-for="(value, key) in deviceTypesWithDevices"
        :key="key"
        :devices="value"
        :device-type="key"
        :on-edit="editDevice"
        :on-delete="openDeleteDeviceConfirmationModal"
        :on-exchange="exchangeDevice"
      />
    </div>
    <div class="fixed bottom-8 right-10 z-10 flex justify-between">
      <ScrollButton
        :button-text="addDeviceButtonLabel"
        :button-icon="addDeviceButtonIcon"
        data-testid="addDeviceButton"
        @on-click="addDevice"
      />
    </div>
    <BasePrompt
      question="Sind Sie sicher, dass Sie dieses Gerät löschen möchten?"
      :open="isDeleteConfirmationModalOpen"
      :cancel="closeDeletionModal"
      title="Gerät löschen"
      :close="closeDeletionModal"
      :proceed="deleteDevice"
    />
    <BasePrompt
      :open="isAccessiblePromptOpen"
      :close="toggleAccessiblePrompt"
      :cancel="cancelIsAccessibleChange"
      :proceed="changeToIsAccessible"
      title="Dieser Raum ist als nicht zugänglich definiert."
      question="Kein Zutritt"
      cancel-text="Weiterhin kein Zutritt"
      proceed-text="Raum zugänglich"
    />
  </div>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue';
import DeviceTypeTable from './DeviceListFormComponents/DeviceTypeTable.vue';
import { useDeviceStore } from '@/store/entities/deviceStore';
import { useEntitiesStore } from '@/store/entities/entitiesStore';
import { FormAction, useFormStore } from '@/store/form/formStore';
import { useInstallationPointStore } from '@/store/entities/installationPointStore';
import BaseInformation from '@/components/UI/Hint/BaseInformation.vue';
import BasePrompt from '@/components/UI/Modal/BasePrompt.vue';
import { Form } from '@/models/Form';
import { entityTypes } from '@/enums/generic';
import ScrollButton from '@/components/UI/Button/ScrollButton.vue';
import { useRoomForm } from '@/composables/useRoomForm';
import { Room } from '@/models';
import { InstallationPoint } from '@/models/installationPoint/InstallationPoint';
import { DeviceClasses } from '@/models/devices/Device';
import { useOrderStore } from '@/store/order/orderStore';

const props = defineProps({
  parentId: {
    type: String,
    required: true,
  },
  isSideBarOpen: {
    type: Boolean,
    required: true,
  },
});
const emit = defineEmits(['update-entity']);

const deviceStore = useDeviceStore();
const installationPointStore = useInstallationPointStore();
const entitiesStore = useEntitiesStore();
const formStore = useFormStore();

const isDeleteConfirmationModalOpen = ref(false);
const isAccessiblePromptOpen = ref(false);
const toBeDeletedInstallationPoint = ref<InstallationPoint | undefined>(
  undefined
);
const deviceTypesWithDevices = computed(() => {
  const devices: {
    [key: string]: DeviceClasses[];
  } = {};

  const devicesInRoom = deviceStore.getAllDevicesInRoom(props.parentId);
  devicesInRoom.forEach((device) => {
    if (!devices[device.deviceType]) {
      devices[device.deviceType] = [];
    }
    devices[device.deviceType].push(device);
  });
  return devices;
});

let localRoom: Room | undefined = undefined;

const { room, handleIsAccessibleChange, handleSubmit } = useRoomForm(
  localRoom,
  props.parentId,
  emit
);

const addDevice = () => {
  localRoom = useEntitiesStore().getEntityById(props.parentId);
  if (!localRoom) return;
  localRoom.isAccessible ? openDeviceForm() : toggleAccessiblePrompt();
};
const openDeviceForm = () => {
  useFormStore().setFormAction(FormAction.CREATE);
  useFormStore().createEntity(
    new Form()
      .setParentId(props.parentId)
      .setName(entityTypes.installationPoint)
  );
};

const exchangeDevice = (installationPoint: InstallationPoint) => {
  if (installationPoint.activeDeviceId) {
    formStore.replaceDevice(installationPoint);
    return;
  }
  formStore.mountDevice(installationPoint);
};

const addDeviceButtonLabel = computed(() => {
  return useOrderStore().isOnSiteInspection()
    ? 'Geräte erfassen'
    : 'Geräte hinzufügen';
});

const addDeviceButtonIcon = computed(() => {
  return useOrderStore().isOnSiteInspection() ? 'pipe-valve' : 'cellphone';
});

const noMountingDevices = computed(() => {
  const devices: {
    [key: string]: InstallationPoint[];
  } = {};
  const installationPoints =
    installationPointStore.getInstallationPointsByRoomId(props.parentId);

  const installationPointsWithDevices = installationPoints.filter(
    (ip) => ip.supportedDeviceType !== undefined
  );
  installationPointsWithDevices.forEach((ip) => {
    if (!ip.activeDeviceId && ip.failedInstallationAttempt) {
      if (!devices['UNMOUNTED']) {
        devices['UNMOUNTED'] = [];
      }
      devices['UNMOUNTED'].push(ip);
    }
  });
  return devices;
});

function editDevice(installationPoint: InstallationPoint) {
  formStore.editDevice(installationPoint, props.parentId);
}

function openDeleteDeviceConfirmationModal(
  installationPoint: InstallationPoint
) {
  toBeDeletedInstallationPoint.value = installationPoint;
  isDeleteConfirmationModalOpen.value = true;
}

function deleteDevice() {
  if (!toBeDeletedInstallationPoint.value) return;

  const relatedDevices = useDeviceStore().getDevicesByInstallationPointId(
    toBeDeletedInstallationPoint.value.id
  );

  for (const device of relatedDevices) {
    entitiesStore.deleteEntity(device);
  }

  if (toBeDeletedInstallationPoint.value.radiatorId) {
    const radiator = useEntitiesStore().getEntityById(
      toBeDeletedInstallationPoint.value.radiatorId
    );
    if (radiator.isPlaceholderRadiator()) {
      entitiesStore.deleteEntity(radiator);
    }
  }

  entitiesStore.deleteEntity(toBeDeletedInstallationPoint.value);
  closeDeletionModal();
}

function closeDeletionModal() {
  isDeleteConfirmationModalOpen.value = false;
  toBeDeletedInstallationPoint.value = undefined;
}

const toggleAccessiblePrompt = () => {
  isAccessiblePromptOpen.value = !isAccessiblePromptOpen.value;
};

const cancelIsAccessibleChange = () => {
  toggleAccessiblePrompt();
  openDeviceForm();
};

const changeToIsAccessible = () => {
  if (!localRoom) return;
  room.value = localRoom;
  handleIsAccessibleChange(true);
  handleSubmit();
  toggleAccessiblePrompt();
  openDeviceForm();
};
</script>
