import { plainToInstance } from 'class-transformer';
import { BusinessEntity } from '@/models';

const plainToClassEntity = (flatBusinessEntity: any) => {
  const entityMappingTypes = [
    'buildings',
    'inspectedBuildings',
    'roomGroups',
    'rooms',
    'radiators',
    'devices',
    'installationPoints',
    'plants',
  ];

  const businessEntity: BusinessEntity = plainToInstance(
    BusinessEntity,
    flatBusinessEntity
  );

  const entityMap = new Map();
  entityMap.set(businessEntity.id, businessEntity);
  entityMappingTypes.forEach((key) => {
    const keyBusinessEntity = key as keyof BusinessEntity;
    const businessEntityObjects = businessEntity[keyBusinessEntity] as Array<
      Record<any, any>
    >;

    businessEntityObjects.forEach((entity) => {
      entityMap.set(entity.id, entity);
    });
  });

  return entityMap;
};

export default {
  plainToClassEntity,
};
